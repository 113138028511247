<template>
  <div class="form">
    <div class="wrapper illustration illustration_shield">
      <Backlink title="questionnaire" service="refCredit" backlink="/refinance-credit/obligations" />
      <div class="content">
        <AboutObligations service="refCredit" nextlink="/refinance-credit/problems" />
      </div>
    </div>
  </div>
</template>

<script>
import Backlink from '@/components/base/Backlink.vue'
import AboutObligations from '@/components/service/AboutObligations.vue'

export default {
  name: 'Refinance_credit_about_obligations',
  components: { Backlink, AboutObligations }
}
</script>
